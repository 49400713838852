import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { utils } from "../../_helpers/utils"
import { getTranslatedText as t } from "../../_locale"
import Logo from "../../styles/img/logo.png"
import Spinner from "../partials/Spinner"
import ExamRight from "./ExamRight"

const ExamHeader = () => {
    const { session } = useSelector((state) => state.session)
    const showExamInfo = () => {
        if (!session.description) return
        utils.createNotification(
            "info",
            <div
                className="text-small"
                dangerouslySetInnerHTML={{
                    __html: session.description
                }}
            />,
            session.name + " " + t("about"),
            10000
        )
    }

    return (
        <header className="header-area">
            <div className="container-fluid">
                <div className="header-wrap style-2">
                    <div className="exam-header-left">
                        <Link to={`${utils.getFirstPath() === "results" ? "/" : "#"}`} className="logo">
                            <img src={Logo} alt="logo" />
                        </Link>

                        <div
                            className={`input-inside exam-name big-screen-exam-title ${
                                session.description ? "has-description" : ""
                            }`}
                            onClick={showExamInfo}
                        >
                            <h2>{session.name}</h2>
                        </div>
                    </div>

                    {utils.getFirstPath() === "exam" && <ExamRight />}
                </div>
            </div>
            <Spinner />
        </header>
    )
}

export default ExamHeader
