import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import Logo from "../../styles/img/logo.png"
import Slider1 from "../../styles/img/slider1.png"
import { tokenActions } from "../../actions/token.actions"
import { spinner } from "../../actions/spinner"
import { utils } from "../../_helpers/utils"
import RegisterForm from "../../components/auth/RegisterForm"
import LoginForm from "../../components/auth/LoginForm"
import Spinner from "../../components/partials/Spinner"
import { getTranslatedText as t } from "../../_locale"
import { userActions } from "../../actions/user.actions"
const Auth = () => {
    const dispatch = useDispatch()
    let history = useHistory()
    const registerRef = useRef(null)
    const user = useSelector((state) => state.user)
    const { activeExams } = useSelector((state) => state.exams)

    const [view, setView] = useState("login")

    const client_token = utils.getStore("client_token")
    const client_token_expires_at = utils.getStore("client_token_expires_at")

    function useQuery() {
        const { search } = useLocation()

        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    const query = useQuery().get("continue")
    let continueParam = query?.slice(-6)

    const changeView = (view) => {
        setView(view)
        registerRef.current.clearErrors()
    }

    useEffect(() => {
        if (
            client_token === null ||
            client_token === undefined ||
            (client_token_expires_at && new Date() > new Date(client_token_expires_at))
        ) {
            dispatch(tokenActions.getClientToken())
        }
    }, [])

    useEffect(() => {
        dispatch(spinner(user.loading))
        if (user && user.user_token) {
            localStorage.auth_token = user.user_token
            history.push(`/`)
        }
    }, [user])

    useEffect(() => {
        user && user.error && user.error !== null && dispatch(userActions.clearErrors())
    }, [])

    return (
        <div className="login-page">
            <header className="navbar navbar-expand navbar-dark flex-row bd-navbar">
                <div className="logo">
                    <a href="/login">
                        <img src={Logo} alt="ExamLingua" />
                    </a>
                </div>
            </header>
            <main role="main">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md lr-forms-area">
                            <div className="lr-forms-content">
                                <div className="welcome">
                                    <h2>{t("welcome")}</h2>
                                    <p>{t("loginWithAccount")}</p>
                                </div>
                                <div className="lr-forms">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <div
                                                className={
                                                    view == "login" ? "nav-item nav-link active" : "nav-item nav-link"
                                                }
                                                role="tab"
                                                aria-controls="nav-login"
                                                aria-selected="true"
                                                onClick={() => {
                                                    changeView("login")
                                                }}
                                            >
                                                {t("login")}
                                            </div>
                                            {/* Register Sekmesi yoruma alındı  */}
                                            {/* <div
                                                className={
                                                    view == "register"
                                                        ? "nav-item nav-link active"
                                                        : "nav-item nav-link"
                                                }
                                                id="nav-register-tab"
                                                data-toggle="tab"
                                                role="tab"
                                                aria-controls="nav-register"
                                                aria-selected="false"
                                                onClick={() => {
                                                    changeView("register");
                                                }}
                                            >
                                                Kaydol
                                            </div> */}
                                            {/* Register Sekmesi yorum sonu  */}
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <LoginForm view={view} continueParam={continueParam} />
                                        {/* <RegisterForm
                                            ref={registerRef}
                                            view={view}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-md slider-area">
                            <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="" src={Slider1} alt="" />
                                        <div className="carousel-caption">
                                            <h5>{t("responsive")}</h5>
                                            <p>{t("webIntro")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Spinner />
        </div>
    )
}

export default Auth
